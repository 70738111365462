import styled from 'styled-components';
import { theme } from 'styles/styled';

const PrivacyPageStyles = styled.main<{ isRtl: boolean }>`
  font-family: 'Roboto';

  max-width: 1020px;
  margin: 160px auto 60px auto;
  padding: ${theme.dimensions.spacingBase16 * 1.5}px;
  padding-bottom: ${theme.dimensions.spacingBase16 * 3}px;

  direction: ${(props) => (props.isRtl ? 'rtl' : 'ltr')};

  a {
    color: ${theme.palette.primary};
  }

  h2 {
    font-size: ${theme.dimensions.spacingBase16 * 1.5}px;
    margin: ${theme.dimensions.spacingBase16 * 1.25}px 0;
  }

  h3 {
    margin-top: 36px;
    margin-bottom: 18px;
    font-size: 20px;
    font-weight: 700;
  }

  p {
    margin: ${theme.dimensions.spacingBase16}px 0;
  }

  strong {
    font-size: 16px;
  }

  h2 > strong {
    font-size: ${theme.fontSize.pageTitleMobile};
  }

  h3 > strong {
    font-size: ${theme.fontSize.titleMobile};
  }

  ol,
  ul {
    list-style: initial;
    padding-left: 40px;
    margin: ${theme.dimensions.spacingBase16}px 0;
  }

  li {
    padding: 6px 0;
    line-height: 24px;
  }
`;

export default PrivacyPageStyles;
