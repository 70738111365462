import React from 'react';
import styled from 'styled-components';
import { graphql, PageProps } from 'gatsby';
import menuLinks from 'settings/menuLinks';
import {
  TranslationsContext,
  TranslationResult,
  translationsFactory,
} from 'settings/i18n';
import { theme } from 'styles/styled';
import PrivacyPageStyles from 'components/PrivacyPageLayout/PrivacyPageLayout';
import EducationNavigation from 'components/EducationNavigation/EducationNavigation';
import { LocationContext } from 'hooks/useLocation';
import Footer from 'components/Footer/Footer';
import SEO from 'components/SEO';
import ClientOnly from 'components/ClientOnly/ClientOnly';

const TermsOfUseStyles = styled(PrivacyPageStyles)`
  p > strong {
    font-size: ${theme.fontSize.pageTitleMobile};
  }
`;

const TermsOfUse = ({
  data,
  location,
}: { data: TranslationResult } & PageProps) => {
  const t = translationsFactory({
    wpPage: {
      id: 'translations',
      translation: {
        ...data.education?.translation,
        ...data.homepage?.translation,
        termsOfUseContent: data.translations.translation.newTermsOfUse,
      },
    },
  });

  return (
    <TranslationsContext.Provider value={t}>
      <LocationContext.Provider value={location}>
        <SEO metadata={data.metadata} url={menuLinks.termsOfUse} />
        <ClientOnly>
          <EducationNavigation />
          <TermsOfUseStyles
            // The documents are already rtl-ed
            isRtl={false}
            dangerouslySetInnerHTML={{ __html: t('termsOfUseContent') }}
          />
          <Footer />
        </ClientOnly>
      </LocationContext.Provider>
    </TranslationsContext.Provider>
  );
};

export default TermsOfUse;

export const query = graphql`
  query($locale: String!) {
    translations: wpPage(
      slug: { regex: "/\\b(\\w*terms-of-use\\w*)+(-\\d+)?\\b/" }
      language: { locale: { eq: $locale } }
    ) {
      id
      translation {
        newTermsOfUse
      }
    }
    
    metadata: wpPage(
      slug: { regex: "/\\b(\\w*terms-of-use\\w*)+(-\\d+)?\\b/" }
      language: { locale: { eq: $locale } }
    ) {
      ...MetaData
    }

    education: wpPage(
      slug: { regex: "/\\b(\\w*education\\w*)+(-\\d+)?\\b$/" }
      language: { locale: { eq: $locale } }
    ) {
      ...HeaderTranslation
    }

    homepage: wpPage(
      slug: { regex: "/\\b(\\w*home-page\\w*)+(-\\d+)?\\b/" }
      language: { locale: { eq: $locale } }
    ) {
      ...HeaderTranslation
      ...FooterTranslation
    }
  }
`;
